@import 'design-system/index';

$spinner-size: 2rem;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  font-size: $spinner-size;
  display: inline-block;
  height: 1em;
  width: 1em;
  box-sizing: border-box;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  opacity: 0.5;

  animation: spin $animationSpeed linear infinite;
}

.spinner_hidden {
  display: none;
}
