.heading {
  padding: 13px 15px 15px 15px;

  .ant-radio-group {
    white-space: nowrap;

    .ant-radio-button-wrapper {
      width: 114px;
      text-align: center;
      font-weight: normal;
    }
    .ant-radio-button-wrapper:first-child {
      border-radius: 4px 0 0 4px;
    }
    .ant-radio-button-wrapper:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.navigation {
  .notifications-bell {
    color: #555E6D;
    font-size: 26px;
  }

  .ant-menu-item-selected {
   .notifications-bell {
     color: #3e6be0;
   }
  }

  .ant-menu {
    background: inherit;
    .ant-menu-item {
      margin: 0 !important;
      padding-left: 15px !important;
      height: 51px;
      line-height: 51px;

      .notification-badge {
        position: absolute;
        right: 16px;
        top: 15px;
        .ant-badge-count {
          color: #D9DFE9;
          background-color: #3e6be0;
        }
        .ant-scroll-number-only .current{
          font-size: 10px;
          font-weight: 800;
          line-height: 0;
        }
      }

      .navigation-badge {
        position: absolute;
        right: 29px;
        top: 15px;
        margin-right: 0;
        .ant-badge-count {
          background-color: inherit;
          color: #555E6D;
          box-shadow: none;
        }
      }
    }
    .ant-menu-item-group {
      .ant-menu-item-group-title {
        display: none;
      }
      .ant-menu-item-group-list {
        .ant-menu-item {
          padding-left: 31px !important;
          .navigation-badge {
            right: 29px;
            .ant-badge-count {
              background-color: #D9DFE9;
            }
          }
        }
      }
    }
    .ant-menu-submenu {
      .navigation-badge {
        position: absolute;
        right: 5px;
        top: 15px;
        .ant-badge-count {
          background-color: #D9DFE9;
          color: #555E6D;
        }
      }
    }
  }
  .ant-menu-inline {
    border: none;
    .ant-menu-item:after {
      left: 0;
      right: inherit;
      border-right: 5px solid #3E6BE0;
    }
  }
}

.upcoming-filter-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      margin: 0 !important;
      padding: 0 0 0 15px !important;
      height: 51px;
      line-height: 51px;

      .navigation-badge {
        position: absolute;
        margin-top: -3px;
        right: 29px;

        .ant-badge-count {
          background-color: inherit;
          color: #555E6D;
          box-shadow: none;
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .upcoming-filter-dropdown {
    width: 259px;
  }
}

@media (max-width: 768px) {
  .upcoming-filter-dropdown {
    width: 100%;
  }
}

.upcoming-filter-text:hover {
  color: #3E6BE0;
}
