@import 'design-system/index';

.heading {
  font-weight: $fontWeight_bold;
  i {
    margin-right: $quarterSpacing;
    font-size: $fontSize_l;
    vertical-align: baseline;
    display: none; // It's only shown when there's a delay
  }
}

// Modifiers
.isDelayed {
  .heading {
    color: $red;
    i {
      display: inline;
    }
  }
}

.isDelivered {
  .heading {
    color: $green_light;
    i {
      display: none;
    }
  }
}
