@import 'design-system/index';

.a {
  @extend %a;
}

.a__wrapper {
  display: inline;
}

// Modifiers

@mixin utility-hover-color($color) {
  &:hover,
  &:focus,
  &:active {
    color: $color;
  }
}

.a_blue {
  color: $blue--fg;
}
.a_red {
  color: $red--fg;
}
.a_white {
  color: $white--fg;
}

.a_utility {
  color: $gray--fg;
}
.a_utility_blue {
  @include utility-hover-color($blue--fg);
}
.a_utility_red {
  @include utility-hover-color($red--fg);
}
.a_utility_white {
  @include utility-hover-color($white--fg);
}

.a_black {
  display: inline-block;
  width: 100%;
  color: inherit;
  font-weight: normal;
}

.a_black:hover {
  color: inherit;
  text-decoration: none;
}

.a_external {
  @extend %a_external;
}

.a_isLoading {
  opacity: 0.7;
  pointer-events: none;
}

.a_isDisabled {
  opacity: 0.4;
  pointer-events: none;
}

.a__wrapper_isDisabled {
  cursor: not-allowed;
}
