@import 'design-system/index';

.avatar {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: $halfSpacing;
}

.avatar__img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar__img_placeholder {
  background: $white--bg;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fontSize_m;
  border: 1px solid $gray--fg;
  color: $gray--fg;
  text-transform: uppercase;
}

// Modifiers

.avatar_md {
  width: 36px;
  height: 36px;
}

.avatar_sm {
  width: 20px;
  height: 20px;

  .avatar__img_placeholder {
    font-size: $fontSize_s;
  }
}
