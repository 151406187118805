.orders-table {
  .table-row {
    .table-column {
      height: 72px;
      vertical-align: top;
    }
  }

  .table-row-clickable {
    .table-column {
      cursor: pointer;
    }
  }

  .table-row-inactive {
    background: #F9F8F9;
  }
}
