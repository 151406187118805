@import 'design-system/index';

.title,
.description {
  @extend %body_legacy;
}

.title {
  font-weight: $fontWeight_bold;
  margin-bottom: $quarterSpacing;
}
