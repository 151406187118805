%a {
  @include resetButton;

  color: $linkColor;
  text-decoration: none;
  font-weight: $fontWeight_bold;
  display: inline;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

%a_unstyled {
  @include resetLink;
}

%a_external {
  color: $linkColor_external;
}
