@import 'design-system/index';

.error {
  color: $errorColor;
}

.label {
  padding-left: 5px;
  margin-bottom: 0;
}
