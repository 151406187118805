.notary-last-name-column {
  min-width: 180px;
  max-width: 220px;

  .notary-photo-container {
    border-radius: 3px;
    margin-right: 5px;

    .notary-photo {
      height: 24px;
      width: 24px;
    }
  }

  .notary-name {
    position: relative;
    top: -0.5em;
  }
}
