.order-information-form {
  .order-information-panel {
    margin-bottom: 7px;
    color: #8792A1;
    border: 0px !important;
    background-color: #FAFBFE;
  }

  .product-item-explanation {
    font-size: 12px;
    font-weight: bold;
    margin-left: 6px;
    color: #555E6D;
  }

  .order-information-input {
    margin-bottom: 7px !important;
  }

  .property-address-info {
      color: #777777;
      font-size: 12px;
      font-style: italic;
      margin-bottom: 5px;
  }

  .ant-form-item {
    margin-bottom: 7px;
  }

  .order-information-header {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 400;
  }

  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #8792A1;
    padding-left: 20px;

    .ant-collapse-arrow {
      left: 5px;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-content.ant-collapse-content-active {
    border: 0px;
    background-color: #FAFBFE;
  }

  .order-information-collapse {
    border: 0px;
    padding-top: 7px;
  }

  .allow-other-return-methods {
    margin-left: 0px;
  }

  .escrow-label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 2px;
  }
}
