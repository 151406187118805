.docs-column {
  text-align: center;

  .docs-number {
    font-weight: bold;
  }

  .upload-icon {
    position: relative;
    top: 3px;
    font-size: 16px;
  }
}
