@import "~antd/lib/style/themes/index";
@import "~antd/lib/style/mixins/index";
@import "~antd/lib/style/core/base";
@import "~antd/lib/style/core/iconfont";
@import "~antd/lib/style/core/motion";
@import "~antd/lib/style/components";


a {
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

@hack: true; @import "/mobile-notary/app/frontend/components/Shared/Ant/StyleOverrides/index.less";@root-entry-name: default;