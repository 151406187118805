@import './Misc';

@card-head-color: @gray-6;
@card-head-font-size: 14px;
@card-head-padding: 14px;
@card-padding-base: 16px;
@card-radius: 4px;

.ant-card {
  box-shadow: 0 3px 4px 0 rgba(223, 227, 230, 0.6);
}

.ant-card-extra {
  padding: 0;
}

.ant-card-head-title {
  font-weight: bold;
}

.ant-card-head {
  margin-bottom: 0;
}
