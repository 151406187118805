.dashboard-header {
  .order-filter {
    float: left;
    margin-bottom: 15px;
  }

  .create-order-button {
    float: right;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-header {
    margin-top: 15px
  }
}
