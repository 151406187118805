@use "sass:math";
@import 'design-system/index';

$iconSize: 1em;

.event {
  position: relative;
  min-height: $spacing * 3;
  padding-bottom: $spacing;
  padding-left: $spacing;

  &:before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: $borderColor;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
  }

  &:last-of-type {
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
}

.event__icon {
  $size: $fontSize_xl;
  height: $size;
  width: $size;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: math.div($size, -2);
  font-size: $size;
  text-align: center;
  border: 1px solid $borderColor;
  border-radius: 50%;
  background: $white;

  i {
    display: none;
  } // The default, "future" state doesn't have an icon.
}

.event__title {
  @extend %body_legacy;
  color: $gray;
}

.event__timestamp {
  @extend %body_s_legacy;
  font-weight: $fontWeight_bold;
  color: $gray_text_light;
}

.event__errorCallout {
  margin-top: $halfSpacing;
  padding: $halfSpacing $spacing;
  background-color: $red_light;
  border: 1px solid darken($red_light, 5%);
  color: $red;
}

// Modifiers

.event_complete {
  .event__icon {
    border: 0;
    color: $green_light;
    i {
      display: block; // Show the icon
      font-size: 1.1em; // the circle is smaller than 1em :(
    }
  }

  .event__title {
    color: $black;
  }
}

.event_error {
  .event__icon {
    color: $errorColor;
    i {
      font-size: 1em;
    }
  }
}
