.order-notifications {
  margin: 0 13px;
  color: #22262E;

  .header {
    margin-bottom: 26px;
  }

  .footer {
    margin-top: 26px;
    margin-bottom: 16px;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .no-new-notifications {
    color: #8792A1;
    font-size: 26px;
    margin-top: 100px;

    .text {
      margin-top: 15px;
    }
  }

  .ant-checkbox-wrapper {
    font: inherit;
  }

  .order-notification {
    border-bottom: 1px solid #D9DFE9;

    :hover {
      cursor: pointer;
      background-color: #F5FAFF !important;

      .ant-col {
        background-color: #F5FAFF;
      }

      .change-read-status{
        display: inline-block;
      }

      .forward-notification{
        display: inline-block;
        margin-right: 20px;
        font-weight: 400;
      }

      .notification-created-at {
        display: none;
      }

      .forwarded-indicator {
        background: #D9DFE9 !important;
      }
    }

    .appointment-time {
      margin-left: 10px;
      font-size: 15px;
    }

    .appointment-time-passed {
      margin-left: 30px;
    }

    .subject, .mark-read, .mark-unread,
    .notification-created-at, .forward-notification-button {
      font-size: 15px;
    }

    .mark-read, .mark-unread {
      color: #3e6be0;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
    }

    .forward-notification-button {
      color:#3E6BE0;
    }

    .forwarded-indicator {
      background: #D9DFE9;
      border-radius: 14px;
      color: #555E6D;
      padding: 2px 5px 2px 5px;
      font-weight: 600;
      font-size: 12px;
      margin-right: 5px;
      overflow: visible;
      display: flex;

      .forwarded-icon {
        padding-top: 2px;
        margin-right: 2px;
      }
    }

    .notification-preview {
      display: flex;
      align-items: center;
    }

    .notification-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .ellipse {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin: 0 8px 2px;
      border-radius: 50%;
    }

    .icon {
      font-size: 20px;
      vertical-align: middle;
    }

    .change-read-status {
      display: none;
    }

    .forward-notification {
      display: none;
    }

    .read {
      min-height: 84px;
      padding: 15px;
      background-color: #FBFCFE;
      color: #555E6D;

      .mark-unread {
        font-weight: 400;
      }

      .icon {
        color: #8792A1;
      }

      .ellipse {
        background-color: #555E6D;
      }

      .read-by {
        .initials {
          display: inline-block;
          margin-right: 8px;
          color: #8792A1;
          vertical-align: middle;
          text-align: center;
          width: 24px;
          height: 24px;
          border: 1px solid #8792A1;
          border-radius: 4px;
        }
      }
    }
    .not-read {
      min-height: 84px;
      padding: 15px;
      font-weight: 600;
      color: #000000;

      .mark-read {
        font-weight: 400;
      }

      .ellipse {
        background-color: #000000;
      }

      .icon {
        color: #3E6BE0;
      }

      .text {
        font-weight: normal;
      }
    }
  }

  .sort-notification {
    .select-label {
      margin-right: 8px;
    }
  }
}

.notification-read-icon {
  margin-right: 5px !important;
}

.forward-notification-content {
  padding: 8px 24px 8px 24px;

  .ant-image {
    padding-right: 10px;
  }

  .forward-notification-data {
    margin-bottom: 8px;
  }

  .forward-notification-commenter-name {
    color: #3E6BE0;
    font-weight: 600;
    margin-right: 5px;
  }

  .forward-notification-date {
    color: #8792A1;
  }

  .forward-notification-text-arrow {
    border: solid 10px transparent;
    border-right-color: #ffffff;
    position: absolute;
    margin: 20px 0 0 -20px;
  }

  .forward-notification-text {
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  .forward-notification-instruction {
    font-size: 15px;
    padding-bottom: 10px;
  }
}

