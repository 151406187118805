%h1 {
  @extend %base-font-styles;
  font-size: $fontSize_xxl;
}

%h2 {
  @extend %base-font-styles;
  font-size: $fontSize_xl;
}

%h3 {
  @extend %base-font-styles;
  font-size: $fontSize_l;
  font-weight: $fontWeight_bold;
}

%h4 {
  @extend %base-font-styles;
  font-size: $fontSize_m;
  font-weight: $fontWeight_bold;
}

%h5 {
  @extend %base-font-styles;
  font-size: $fontSize_s;
  font-weight: $fontWeight_bold;
  text-transform: uppercase;
}

// We will deprecate these styles soon...
%heading_l {
  @include base-font-styles-legacy();
  font-size: $fontSize_xxl;
}

%heading_m {
  @include base-font-styles-legacy();
  font-size: $fontSize_xl;
}

%heading_s {
  @include base-font-styles-legacy();
  font-size: $fontSize_l;
  font-weight: $fontWeight_bold;
}

%heading_xs {
  @include base-font-styles-legacy();
  font-size: $fontSize_m;
  font-weight: $fontWeight_bold;
  color: $gray_text_dark;
}

%heading_label {
  @include base-font-styles-legacy();
  font-size: $fontSize_s;
  text-transform: uppercase;
  color: $ui_5;
}
