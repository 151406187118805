.email-routing {
  padding: 15px 23.5px 70px 28.5px;

  .routing-subtitle {
    margin-top: 32px;
    font-size: 20px;
  }

  .routing-form-item {
    label {
      font-size: 14px;
      font-weight: normal;
      color: #000000;
    }

    input {
      font-size: 14px;
    }

    margin-bottom: 14px;
  }

  .save-form-button {
    margin-top: 22px;
  }

  .routing-header {
    margin-bottom: 30px;

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      color: #555E6D;
      margin-bottom: 0;
    }
  }

  .routing-footer {
    margin-top: 30px;
  }
}

