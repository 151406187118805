%base-font-styles {
  color: $fontColor;
  line-height: $lineHeight;
  font-weight: $fontWeight_medium;
  // TODO Add graphik

  &.bold {
    font-weight: $fontWeight_bold;
  }

  &.muted {
    color: $gray_text;
  }

  &.inline {
    display: inline;
  }
}

// We will deprecate this soon...
@mixin base-font-styles-legacy() {
  color: $fontColor;
  line-height: $lineHeight;
  font-weight: $fontWeight_medium;
}
