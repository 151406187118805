.mark-all-read-button {
  .icon {
    font-size: 18px;
    margin-right: 3px;
    vertical-align: middle;
  }
}

.mark-all-read-notification {
  width: 450px;

  .icon {
    margin-top: 2px;
    font-size: 26px;
    line-height: 26px;
    color: #0BA169;
  }

  .close-icon-wrapper {
    font-size: 16px;
    .close-icon {
      margin-top: 8px;
    }
  }
}

.mark-as-read-bulk-container {
  padding: 25px;
  text-align: center;

  .title {
    font-size: 16px;
  }
}

