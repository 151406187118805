.pan {
  padding: 0 !important;
}

.pas {
  padding: @base-unit / 2 !important;
}

.pam {
  padding: @base-unit !important;
}

.pal {
  padding: @base-unit * 2 !important;
}

.paxl {
  padding: @base-unit * 4 !important;
}

.paxxl {
  padding: @base-unit * 6 !important;
}

.paxxxl {
  padding: @base-unit * 8 !important;
}

.ptn {
  padding-top: 0 !important;
}

.pts {
  padding-top: @base-unit / 2 !important;
}

.ptm {
  padding-top: @base-unit !important;
}

.ptl {
  padding-top: @base-unit * 2 !important;
}

.ptxl {
  padding-top: @base-unit * 4 !important;
}

.ptxxl {
  padding-top: @base-unit * 6 !important;
}

.ptxxxl {
  padding-top: @base-unit * 8 !important;
}

.prn {
  padding-right: 0 !important;
}

.prs {
  padding-right: @base-unit / 2 !important;
}

.prm {
  padding-right: @base-unit !important;
}

.prl {
  padding-right: @base-unit * 2 !important;
}

.prxl {
  padding-right: @base-unit * 4 !important;
}

.prxxl {
  padding-right: @base-unit * 6 !important;
}

.prxxxl {
  padding-right: @base-unit * 8 !important;
}

.pbn {
  padding-bottom: 0 !important;
}

.pbs {
  padding-bottom: @base-unit / 2 !important;
}

.pbm {
  padding-bottom: @base-unit !important;
}

.pbl {
  padding-bottom: @base-unit * 2 !important;
}

.pbxl {
  padding-bottom: @base-unit * 4 !important;
}

.pbxxl {
  padding-bottom: @base-unit * 6 !important;
}

.pbxxxl {
  padding-bottom: @base-unit * 8 !important;
}

.pln {
  padding-left: 0 !important;
}

.pls {
  padding-left: @base-unit / 2 !important;
}

.plm {
  padding-left: @base-unit !important;
}

.pll {
  padding-left: @base-unit * 2 !important;
}

.plxl {
  padding-left: @base-unit * 4 !important;
}

.plxxl {
  padding-left: @base-unit * 6 !important;
}

.plxxxl {
  padding-left: @base-unit * 8 !important;
}

.phn {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.phs {
  padding-left: @base-unit / 2 !important;
  padding-right: @base-unit / 2 !important;
}

.phm {
  padding-left: @base-unit !important;
  padding-right: @base-unit !important;
}

.phl {
  padding-left: @base-unit * 2 !important;
  padding-right: @base-unit * 2 !important;
}

.phxl {
  padding-left: @base-unit * 4 !important;
  padding-right: @base-unit * 4 !important;
}

.phxxl {
  padding-left: @base-unit * 6 !important;
  padding-right: @base-unit * 6 !important;
}

.phxxxl {
  padding-left: @base-unit * 8 !important;
  padding-right: @base-unit * 8 !important;
}

.pvn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pvs {
  padding-top: @base-unit / 2 !important;
  padding-bottom: @base-unit / 2 !important;
}

.pvm {
  padding-top: @base-unit !important;
  padding-bottom: @base-unit !important;
}

.pvl {
  padding-top: @base-unit * 2 !important;
  padding-bottom: @base-unit * 2 !important;
}

.pvxl {
  padding-top: @base-unit * 4 !important;
  padding-bottom: @base-unit * 4 !important;
}

.pvxxl {
  padding-top: @base-unit * 6 !important;
  padding-bottom: @base-unit * 6 !important;
}

.pvxxxl {
  padding-top: @base-unit * 8 !important;
  padding-bottom: @base-unit * 8 !important;
}
