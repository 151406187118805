.ant-tag {
  border: 0;
  border-radius: 4 * @base-unit;
  font-weight: bold;
  padding: 0 2 * @base-unit;
}

.ant-tag-gray {
  background: @gray-4;
  color: @gray-7;
}

.ant-tag-blue {
  background: @blue-2;
  color: @blue-6;
}

.ant-tag-green {
  background: @green-2;
  color: @green-6;
}

.ant-tag-orange {
  background: @orange-2;
  color: @orange-6;
}

.ant-tag-red {
  background: @red-2;
  color: @red-6;
}

.ant-tag-purple {
  background: @purple-2;
  color: @purple-6;
}
