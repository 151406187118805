.appointment-full-time-column {
  min-width: 117px;

  .status-icon {
    position: relative;
    top: 3px;
    margin-right: 2px;
    font-size: 16px;
    color: #0ba169;
  }
}
