@import 'design-system/index';

.formRow {
  display: flex;
  + .formRow {
    margin-top: $halfSpacing;
  }
}

.formRow_controls {
  justify-content: flex-end;
  align-items: center;
  padding: $halfSpacing 0;
}

.formRow_controls__cancelButton {
  margin-right: $spacing;
}

.label__required {
  @extend %micro;
  color: $gray_text;
  margin-left: $halfSpacing;
}

.input {
  width: 100%;
  + .input {
    margin-left: $halfSpacing;
  }
}

.input_city {
  flex: 1 1 none;
}

.input_state {
  flex: 1 0 70px;
}

.input_zip {
  flex: 1 0 100px;
}

.formElement__error {
  color: $errorColor;
  margin-top: $quarterSpacing;
  line-height: $lineHeight_small;
}
.formElement__hint {
  @extend %micro;
  color: $ui_7;
}

.formError {
  margin-top: $spacing;
}

.required:after {
  content: '*';
}
