.status-column {
  min-width: 200px;
  max-width: 360px;

  .owner-avatar {
    margin-right: 0.6rem;
  }

  .rejected-icon {
    position: relative;
    top: 3px;
    margin-right: 2px;
    color: #B52020;
  }

  .auto-reminder-icon {
    position: relative;
    top: 3px;
    margin-right: 2px;
    color: #8792A1;
    font-size: 15px;
  }

  .active-tag,
  .completed-tag,
  .approved-tag,
  .confirming-with-notary-tag {
    color: #01704D;
    background-color: #CBE9DD;
    font-weight: 600;
  }

  .stopped-tag,
  .on-hold-tag,
  .documents-note,
  .scanbacks-note,
  .notary-needs-access-to-docs-note,
  .needs-confirmation-from-notary-note,
  .notary-needs-to-download-docs-note,
  .needs-shipping-label-note {
    color: #B74F03;
    background-color: #FFF8D6;
    font-weight: 600;
  }

  .rejected-tag,
  .canceled-tag {
    color: #B52020;
    background-color: #FFD9D6;
    font-weight: 600;
  }

  .status-tag {
    background-color: #E5E9EF;
    font-weight: 600;
  }

  .viewing-users {
    padding-left: 5px;
    float: right;
    text-align: right;
  }
}
