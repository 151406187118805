.man {
  margin: 0 !important;
}

.mas {
  margin: @base-unit / 2 !important;
}

.mam {
  margin: @base-unit !important;
}

.mal {
  margin: @base-unit * 2 !important;
}

.maxl {
  margin: @base-unit * 4 !important;
}

.maxxl {
  margin: @base-unit * 6 !important;
}

.maxxxl {
  margin: @base-unit * 8 !important;
}

.mtn {
  margin-top: 0 !important;
}

.mts {
  margin-top: @base-unit / 2 !important;
}

.mtm {
  margin-top: @base-unit !important;
}

.mtl {
  margin-top: @base-unit * 2 !important;
}

.mtxl {
  margin-top: @base-unit * 4 !important;
}

.mtxxl {
  margin-top: @base-unit * 6 !important;
}

.mtxxxl {
  margin-top: @base-unit * 8 !important;
}

.mrn {
  margin-right: 0 !important;
}

.mrs {
  margin-right: @base-unit / 2 !important;
}

.mrm {
  margin-right: @base-unit !important;
}

.mrl {
  margin-right: @base-unit * 2 !important;
}

.mrxl {
  margin-right: @base-unit * 4 !important;
}

.mrxxl {
  margin-right: @base-unit * 6 !important;
}

.mrxxxl {
  margin-right: @base-unit * 8 !important;
}

.mbn {
  margin-bottom: 0 !important;
}

.mbs {
  margin-bottom: @base-unit / 2 !important;
}

.mbm {
  margin-bottom: @base-unit !important;
}

.mbl {
  margin-bottom: @base-unit * 2 !important;
}

.mbxl {
  margin-bottom: @base-unit * 4 !important;
}

.mbxxl {
  margin-bottom: @base-unit * 6 !important;
}

.mbxxxl {
  margin-bottom: @base-unit * 8 !important;
}

.mln {
  margin-left: 0 !important;
}

.mls {
  margin-left: @base-unit / 2 !important;
}

.mlm {
  margin-left: @base-unit !important;
}

.mll {
  margin-left: @base-unit * 2 !important;
}

.mlxl {
  margin-left: @base-unit * 4 !important;
}

.mlxxl {
  margin-left: @base-unit * 6 !important;
}

.mlxxxl {
  margin-left: @base-unit * 8 !important;
}

.mhn {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mhs {
  margin-left: @base-unit / 2 !important;
  margin-right: @base-unit / 2 !important;
}

.mhm {
  margin-left: @base-unit !important;
  margin-right: @base-unit !important;
}

.mhl {
  margin-left: @base-unit * 2 !important;
  margin-right: @base-unit * 2 !important;
}

.mhxl {
  margin-left: @base-unit * 4 !important;
  margin-right: @base-unit * 4 !important;
}

.mhxxl {
  margin-left: @base-unit * 6 !important;
  margin-right: @base-unit * 6 !important;
}

.mhxxxl {
  margin-left: @base-unit * 8 !important;
  margin-right: @base-unit * 8 !important;
}

.mvn {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mvs {
  margin-top: @base-unit / 2 !important;
  margin-bottom: @base-unit / 2 !important;
}

.mvm {
  margin-top: @base-unit !important;
  margin-bottom: @base-unit !important;
}

.mvl {
  margin-top: @base-unit * 2 !important;
  margin-bottom: @base-unit * 2 !important;
}

.mvxl {
  margin-top: @base-unit * 4 !important;
  margin-bottom: @base-unit * 4 !important;
}

.mvxxl {
  margin-top: @base-unit * 6 !important;
  margin-bottom: @base-unit * 6 !important;
}

.mvxxxl {
  margin-top: @base-unit * 8 !important;
  margin-bottom: @base-unit * 8 !important;
}
