@import './colors';

.background-ant-gray-1 {
  background: @ant-gray-1 !important;
}

.background-ant-gray-2 {
  background: @ant-gray-2 !important;
}

.background-ant-gray-3 {
  background: @ant-gray-3 !important;
}

.background-ant-gray-4 {
  background: @ant-gray-4 !important;
}

.background-ant-gray-5 {
  background: @ant-gray-5 !important;
}

.background-ant-gray-6 {
  background: @ant-gray-6 !important;
}

.background-ant-gray-7 {
  background: @ant-gray-7 !important;
}

.background-ant-gray-8 {
  background: @ant-gray-8 !important;
}

.background-ant-blue-1 {
  background: @ant-blue-1 !important;
}

.background-ant-blue-2 {
  background: @ant-blue-2 !important;
}

.background-ant-blue-3 {
  background: @ant-blue-3 !important;
}

.background-ant-blue-4 {
  background: @ant-blue-4 !important;
}

.background-ant-blue-5 {
  background: @ant-blue-5 !important;
}

.background-ant-blue-6 {
  background: @ant-blue-6 !important;
}

.background-ant-green-1 {
  background: @ant-green-1 !important;
}

.background-ant-green-2 {
  background: @ant-green-2 !important;
}

.background-ant-green-3 {
  background: @ant-green-3 !important;
}

.background-ant-green-4 {
  background: @ant-green-4 !important;
}

.background-ant-green-5 {
  background: @ant-green-5 !important;
}

.background-ant-green-6 {
  background: @ant-green-6 !important;
}

.background-ant-orange-1 {
  background: @ant-orange-1 !important;
}

.background-ant-orange-2 {
  background: @ant-orange-2 !important;
}

.background-ant-orange-3 {
  background: @ant-orange-3 !important;
}

.background-ant-orange-4 {
  background: @ant-orange-4 !important;
}

.background-ant-orange-5 {
  background: @ant-orange-5 !important;
}

.background-ant-orange-6 {
  background: @ant-orange-6 !important;
}

.background-ant-red-1 {
  background: @ant-red-1 !important;
}

.background-ant-red-2 {
  background: @ant-red-2 !important;
}

.background-ant-red-3 {
  background: @ant-red-3 !important;
}

.background-ant-red-4 {
  background: @ant-red-4 !important;
}

.background-ant-red-5 {
  background: @ant-red-5 !important;
}

.background-ant-red-6 {
  background: @ant-red-6 !important;
}

.background-ant-violet-1 {
  background: @ant-violet-1 !important;
}

.background-ant-violet-2 {
  background: @ant-violet-2 !important;
}

.background-ant-violet-3 {
  background: @ant-violet-3 !important;
}

.background-ant-violet-4 {
  background: @ant-violet-4 !important;
}

.background-ant-violet-5 {
  background: @ant-violet-5 !important;
}

.background-ant-violet-6 {
  background: @ant-violet-6 !important;
}

.background-ant-purple-1 {
  background: @ant-purple-1 !important;
}

.background-ant-purple-2 {
  background: @ant-purple-2 !important;
}

.background-ant-purple-3 {
  background: @ant-purple-3 !important;
}

.background-ant-purple-4 {
  background: @ant-purple-4 !important;
}

.background-ant-purple-5 {
  background: @ant-purple-5 !important;
}

.background-ant-purple-6 {
  background: @ant-purple-6 !important;
}
