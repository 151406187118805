.eligibilityToolSettings{
  font-family: Open Sans;

  .ruleSpace {
    width: 30%;
    padding: 10px;
    display: block;
  }

  .ruleLogicFieldsWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .ruleField {
    width: 200px;
  }

  .ruleNameText{
    font-size: 13pt;
    font-weight: 600;
  }

  .ruleNameEditor {
    font-size: 12pt;
    width: 200px;
  }

  .ruleLogicText {
    font-weight: 400;
    font-size: 11pt;
    line-height: 22px;
  }

  .inEligibilityReasonComponent {
    padding-top: 20px;
  }

  .inEligibilityReasonText {
    font-size: 11pt;
  }

  .customizeButton {
    padding-left: 0px;
    width: 210px;
    height: 24px;
    font-size: 12pt;
  }

  .customReasonHeader {
    font-size: 12pt;
    font-weight: 600;
  }

  .ruleEditorHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .ruleEditorActionButtons {
    display: flex;
    gap: 8px;
  }

  .notification {
    position: relative;
    top: 80px;
  }
}

