// START: Consumer Colors
// These colors are considered ADA-accessible
// Legend: $<color><#>--<usage>
//   color: name of color
//   #: intensity (20 is darker than 10)
//   usage: fg = foreground, bg = background

$black--fg: #333;
$gray--fg: #6d7274;
$white--fg: #fff;
$red--fg: #a94442;
$blue--fg: #337ab7;

$white--bg: #fff;
$gray10--bg: #f8f9fb;
$gray20--bg: #74777a;
$blue--bg: #337ab7;
$green--bg: #00875a;
$red10--bg: #f2dede;
$red20--bg: #a94442;
$orange--bg: #fc9835;
$yellow--bg: #fef9f4;

// END: Consumer Colors

// --- Utility color ramp --- //

$ui_black: #000000;
$ui_1: #1a2532;
$ui_2: #29323e;
$ui_3: #38404a;
$ui_4: #474e56;
$ui_5: #565b62;
$ui_6: #65696e;
$ui_7: #74777a;
$ui_8: #85898c;
$ui_9: #979b9e;
$ui_a: #a9adb0;
$ui_b: #bbbfc2;
$ui_c: #cdd1d4;
$ui_d: #dfe3e6;
$ui_e: #ebeef0;
$ui_f: #f8f9fb;
$ui_g: #fafafb;
$ui_white: #ffffff;

$blue: #337ab7;
$blue_dark: #005b79;
$green: #00875a;
$green_light: #5cb85c;
$red: #c03732;
$red_light: #f2dede;
$orange: #e47819;
$yellow: #ffa500;

$orange_0: #fc9835;
$orange_1: #fef9f4;

$purple_0: #424b8a;
$purple_1: #dfebfb;

$brown_0: #893d1d;
$brown_1: #f7ebd4;

$green_0: #2c5e45;
$green_1: #d9f2e1;

// --- LEGACY stuff --- //

// Instead, use the equivalent color from "$ui__" ramp
$gray_0: #000;
$gray_1: #111;
$gray_2: #222;
$gray_3: #333;
$gray_4: #444;
$gray_5: #555;
$gray_6: #666;
$gray_7: #777;
$gray_8: #888;
$gray_9: #999;
$gray_a: #aaa;
$gray_b: #bbb;
$gray_c: #ccc;
$gray_d: #ddd;
$gray_e: #eee;
$gray_f: #fafafa;
$white: #fff;

$black: $gray_3;
$gray: $gray_9;

// --- end legacy --- //

// --- TEXT COLORS --- //
$default_text: $ui_1;

$gray_text: $ui_7;
$gray_text_light: $ui_a;
$gray_text_dark: $ui_5;

// --- STATUS COLORS --- //
$errorColor: #b94a48;
$successColor: $green;
$warningOrange: #d59233;

// --- ELEMENT COLORS --- //
$fontColor: $default_text;

$linkColor: $blue;
$linkColor_external: $blue_dark;

$borderColor: $ui_d;

$bgColor: $white;
$bgColor_alt: $ui_f;
