.notification-settings {
  padding: 15px 23.5px 70px 28.5px;

  p {
    margin-bottom: 0;
  }

  .settings-header {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      color: #555E6D;
    }
  }

  .settings-block {
    margin-top: 30px;

    .settings-block-title {
      h4 {
        font-size: 20px;
      }
    }

    .settings-block-content {
      border: 1px solid #D9DFE9;
      border-radius: 4px;

      .settings-block-header {
        font-size: 16px;
        color: #555E6D;
        background-color: #FAFBFE;
        padding: 15px;
        border-bottom: 1px solid #D9DFE9;
      }

      .settings-block-row {
        padding: 15px;
        border-bottom: 1px solid #D9DFE9;

        .settings-block-row-title {
          font-size: 14px;
        }

        .settings-block-row-description {
          font-size: 12px;
          color: #5f6876;
        }

        .settings-block-row-options {
          font-size: 14px;

          a {
            color: inherit;
          }

          .icon {
            vertical-align: middle;
          }

          .selected-values {
            margin-left: 5px;
          }

          .preview-link {
            color: #3e6be0;
            margin-left: 10px;

            .preview-icon {
              margin-right: 3px;
            }
          }
        }

        .setting-not-available {
          font-size: 14px;
          color: #8792A1;
        }
      }
    }
  }

  .settings-footer {
    margin-top: 30px;

    .reset-link {
      margin-left: 15px;
      font-size: 14px;
    }
  }
}

.setting-options {
  font-weight: normal !important;
}

