@import 'design-system/index';

.statusBanner {
  margin: $halfSpacing 0 $spacing;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusBanner_error {
  color: $red;
  background-color: $red_light;
  border: 1px solid darken($red_light, 5%);
}

.statusBanner__spinner {
  margin-right: $spacing;
  font-size: 1em;
}

.trackingInfo {
  margin-top: $spacing;
  color: $gray_text;
}

.trackingInfo__number {
  font-weight: $fontWeight_bold;
}

.trackingInfo__lastUpdated {
  i {
    cursor: pointer;
    transition: color $transitionSpeed_faster;
    padding: $quarterSpacing;
    &:hover {
      color: $default_text;
    }
  }
}

.trackingLink {
  display: block;
  padding: $halfSpacing;
  text-align: center;
  border: 1px solid $borderColor;
  color: $default_text;
  border-radius: $borderRadius;

  &:hover {
    border-color: $gray;
    text-decoration: none;
    color: $default_text;
  }
}
