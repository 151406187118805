@import 'design-system/index';

.signerView {
  display: flex;
  align-items: flex-start;
  margin-bottom: $halfSpacing;
}
.signerView__avatar {
  flex: 0 0 auto;
  margin-right: $halfSpacing;
}
.signerView__details {
  flex: 1 1 auto;
}
