.add-client-team-member-form {
  .form-row {
    display: block !important;
  }

  .name-row {
    display: flex;
  }

  .ant-form-item-label {
    text-align: left;
  }
  
  .phone-input {
    width: 100%;
  }

  .cancel-button {
    margin-right: 20px;
    margin-left: 15px;
    font-size: 14px;
  }

  .avatar-section {
    margin-top: 15px;
    margin-left: 15px;
    display: inline-flex !important;
  }

  .avatar {
    margin-right: 15px;
  }

  .errors-section {
    p {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0;
    }
    li {
      font-size: 13px;
    }
  }
}
