%body {
  @extend %base-font-styles;
  font-size: $fontSize_m;
}

%micro {
  @extend %base-font-styles;
  font-size: $fontSize_s;
  letter-spacing: 0.02em;
}

// We will deprecate these styles soon...
%body_legacy {
  @include base-font-styles-legacy();
  font-size: $fontSize_m;
}

%body_s_legacy {
  @include base-font-styles-legacy();
  font-size: $fontSize_s;
  letter-spacing: 0.02em;
}
