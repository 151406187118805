@import 'design-system/index';

$spacingBasis: 10px;

.panel {
  border-bottom: 1px solid $borderColor;
  padding: $spacingBasis $spacingBasis * 1.5;
}

.panel__heading {
  color: $ui_9;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.panel__editButton {
  font-weight: $fontWeight_medium;
}

.panel__content {
  margin-top: $spacingBasis * 0.8;
}
