.board-header {
  .notary-filter {
    float: left;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .board-header {
    margin-top: 15px
  }
}
