@import 'design-system/index';

$buttonShadowDistance: 1px;
$buttonShadowDistance_onHover: 3px;
$buttonMoveDistance: $buttonShadowDistance_onHover - $buttonShadowDistance;

@mixin buttonColors_primary($color: $blue--bg, $text-color: $white--fg) {
  background-color: $color;
  border: 1px solid scale-color($color, $lightness: -25%);
  color: $text-color;
  &:hover,
  &:focus {
    background-color: scale-color($color, $lightness: 10%);
    color: $text-color;
    text-decoration: none;
  }
  &:active {
    background-color: scale-color($color, $lightness: -10%);
    color: $text-color;
  }
}

@mixin buttonColors_secondary($color: $blue--bg, $text-color: $blue--fg) {
  border: 1px solid scale-color($gray10--bg, $lightness: -10%);
  color: $text-color;
  &:hover,
  &:focus {
    border-color: $color;
    color: $text-color;
    text-decoration: none;
  }
  &:active {
    color: $text-color;
  }
}

.button {
  @include resetButton;
  display: inline-block;
  position: relative;
  font-size: $fontSize_m;
  font-weight: $fontWeight_bold;
  box-shadow: 0 $buttonShadowDistance 1px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius;
  text-transform: none;
  text-decoration: none;

  // Hover fix to make sure button remains hovered while it moves up.
  &:after {
    content: '';
    position: absolute;
    right: 0%;
    left: 0%;
    top: 100%;
    height: 0px;
  }
  &:hover,
  &:focus {
    box-shadow: 0 $buttonShadowDistance_onHover 5px rgba(0, 0, 0, 0.2);
    // We are temporaily disabling hover movement to be more consistent with other btns.
    // Uncomment the following lines when we consolidate button styles.

    // transform: translateY($buttonMoveDistance * -1);
    // &:after {
    //   height: 5px;
    // }
  }
  &:active {
    transform: translateY(0px);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  }
}

// Size
.button_small {
  padding: $spacing * 0.25 $spacing;
}
.button_medium {
  padding: $spacing * 0.5 $spacing;
}
.button_large {
  padding: $spacing * 0.75 $spacing;
  font-size: $fontSize_l;
}

// Styles & colors
.button_primary_blue {
  @include buttonColors_primary($blue--bg);
}
.button_primary_green {
  @include buttonColors_primary($green--bg);
}
.button_primary_orange {
  @include buttonColors_primary($orange--bg);
}
.button_primary_red {
  @include buttonColors_primary($red20--bg);
}

.button_secondary {
  background-color: $white--bg;
}
.button_secondary_blue {
  @include buttonColors_secondary($blue--bg, $blue--fg);
}
.button_secondary_red {
  @include buttonColors_secondary($red20--bg, $red--fg);
}

.button_utility {
  background-color: $white--fg;
  color: $gray--fg;
  border: 1px solid scale-color($gray10--bg, $lightness: -10%);
  &:hover,
  &:focus {
    border-color: $blue--bg;
    color: $gray--fg;
  }
  &:active {
    color: $gray--fg;
  }
}

.button_inverse {
  background-color: $white--fg;
  color: $blue;
  border: 1px solid $blue;
}

.button__wrapper {
  display: inline-block;
}

// Modifier states
.button_isDisabled {
  opacity: 0.4;
  pointer-events: none;
}
.button_isLoading {
  opacity: 0.7;
  pointer-events: none;
}

.button__wrapper_isDisabled {
  cursor: not-allowed;
}

.button__wrapper_fullWidth {
  width: 100%;

  button {
    width: 100%;
  }
}
