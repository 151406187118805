@import './Misc';

@input-border-color: @gray-5;
@input-hover-border-color: @gray-7;
@input-placeholder-color: @gray-5;

.ant-input-search-button {
  &,
  &[disabled] {
    border: 1px solid @gray-5;
  }
}
