@import 'design-system/index';

.callout {
  @extend %body_s_legacy;
  border-radius: $borderRadius;
  padding: $spacing * 0.75 $spacing;
}

.callout_gray {
  color: $gray_text_dark;
  background-color: $ui_f;
  border: 1px solid $borderColor;
}

.callout_red {
  color: $red;
  background-color: $red_light;
  border: 1px solid darken($red_light, 8%);
}

.callout_yellow {
  color: darken($warningOrange, 20%);
  background-color: scale-color($warningOrange, $lightness: 90%);
  border: 1px solid scale-color($warningOrange, $lightness: 80%);
}

.callout_blue {
  color: darken($blue, 20%);
  $blue_bg: scale-color($blue, $lightness: 90%);
  background-color: $blue_bg;
  border: 1px solid darken($blue_bg, 5%);
}

.callout_green {
  color: darken($green, 5%);
  $green_bg: scale-color($green_light, $lightness: 90%);
  background-color: $green_bg;
  border: 1px solid darken($green_bg, 10%);
}
