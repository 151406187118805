@import 'design-system/index';

.label {
  margin-right: $halfSpacing;
}

.select {
  display: inline-block;
  width: auto;
  margin-right: $halfSpacing;
}

.saved {
  display: inline-block;
  color: $successColor;
}

.error {
  margin-top: $halfSpacing;
  color: $errorColor;
}

.description {
  @extend %body_s_legacy;
  color: $gray;
  max-width: 300px;
  margin-top: $halfSpacing;
}
