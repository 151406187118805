.table-column ~.notary-last-name-column {
  min-width: 130px;
  max-width: 130px;

  .auto-assigned-icon {
    color: #74777a;
  }

  .verified-icon {
    color: #0ba169;
    margin-right: 2px;
  }

  .auto-assigned-icon, .verified-icon {
    position: relative;
    top: 3px;
  }

  .notary-is-unreachable {
    background-color: #B52020;
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  }
}
