.autoFundSettingsForm {
  .ant-form-item-label {
    min-width: 272px;
    text-align: left;
  }

  label {
    font-weight: normal;
  }

  .formCheckbox {
    span {
      padding-right: 0 !important;
    }
  }

  .formInput {
    margin-left: 8px;

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .rejectSettlementOfficeIds {
    margin-left: 24px;
    max-width: 416px;
  }

  .ant-select-selection-item .officeLabelContainer .ant-space-item:not(:first-child) {
    display: none;
  }
}

.notification {
  position: relative;
  top: 80px;
}
