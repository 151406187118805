.comment-form {
  .to-field {
    margin-bottom: 0;
  }

  .send-email-notification-field, .send-email-notification-field-no-checkbox {
    margin-bottom: 10px;

    label {
      font-weight: normal;
    }

    .ant-form-item-explain {
      padding-left: 24px;
      font-size: 12px;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }
  }

  .send-email-notification-field-no-checkbox {
    .ant-form-item-explain {
      padding-left: 0;
    }

    .ant-form-item-control-input {
      min-height: 0;
    }
  }

  .submit-field {
    float: right;
  }
}
