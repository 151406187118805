.add_team_members_form {
  h2 {
    margin-top: 10px;
  }
  input, .ant-select-selector {
    border: 1px solid #D9DFE9 !important;
  }
  label {
    font-weight: bold;
    font-family: 'Open Sans', arial, sans-serif;
    color: #22262E;
  }
}

.csv_form_container {
  width: 50%;
}

.description {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #555E6D;
}

.add_team_member_footer {
  margin-top: 40px;
}

.csv_cancel {
  margin-right: 20px;
}

.csv_instructions_container {
  margin-bottom: 35px;
}

.csv_instruction {
  margin-bottom: 32px;
}

.list_item_instructions {
  font-weight: bold;
  margin-bottom: 3px;
}

.csv_instruction_content {
  margin-left: 14px;
}

.csv_template {
  color: #3E6BE0;
}

.csv_drag_drop_container {
  margin-top: 53px;
}

.ant-upload-list-item-name {
  color: #3E6BE0;
}

.ctm_errors {
  p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
  }
  li {
    font-size: 13px;
  }
}

.csv_error_instruction {
  margin-top: 21px
}

.my-team {
  .ant-input-affix-wrapper {
    max-height: 40px;
    height: 40px;
    input {
      font-size: 14px;
    }
    .ant-input-clear-icon {
      color: #3E6BE0;
      font-size: 21px;
    }
  }
  .ant-table-wrapper {
    margin-top: 22px;
  }
  .ant-table-thead {
    .filtered-column-icon {
        color: #3E6BE0;
    }
  }
  .ant-table-tbody {
    .table-column {
      height: 58px;
      .email {
        font-size: 12px;
        color: #555E6D;
      }
      .ant-tag {
        width: 69px;
        text-align: center;
      }
      &:not(:last-of-type) {
        border-right: none !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .ant-table-column-sort {
    background: unset;
  }
  .ant-table-row > .ant-table-selection-column {
    height: 58px;
    padding: 0px !important;

    .selectionCell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.team-table-filter {
  padding: 10px 8px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .ant-checkbox-wrapper {
      font-weight: normal;
      margin-left: 0px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
      .ant-checkbox-checked::after {
        max-height: 16px;
      }
    }
  }
  .filter-role-form {
    .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      .role-description-container {
        display: flex;
        flex-direction: column;
        position: relative;
        bottom: 5px;

        .role-description {
          font-size: 12px;
        }
      }
    }
  }
  .ant-btn {
    width: calc(50% - 4px);
  }
}

.team-member-modal {
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-close-x {
    line-height: 50px;
  }
  .input-mask {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #8792a1;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .footer-label {
    color: #999;
    min-width: 101px;
    text-align: right;
    display: block;
    margin-right: 20px;
    font-weight: 700;
  }
}

.team-member-notification {
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
}

.export-team-members-csv-modal {
  .csv-modal-title {
    display: flex;
    align-items: center;
    span {
      color: #22262E;
    }
  }
  .ant-spin-text {
    margin-top: 0.6rem;
    color: #22262E;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: #22262E;
  }
  .ant-modal-footer {
    border: none;
  }
}
