.ReactTable {
  border: none;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
  display: flex;
  align-items: center;
  white-space: unset;
  word-break: break-all;
  word-break: break-word;
}

.ReactTable .rt-thead .rt-th:first-child,
.ReactTable .rt-tbody .rt-td:first-child {
  padding-left: 1.23rem; /* $spacing */
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-tbody .rt-td:last-child {
  padding-right: 1.23rem; /* $spacing */
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #dfe3e6; /* $ui_d */
}

.ReactTable .rt-thead .rt-th {
  border-right: none;
  text-align: left;
  color: #74777a; /* $ui_7 */
}

.ReactTable .rt-thead .rt-th:focus {
  outline: 0;
}

.ReactTable .-pagination .-center {
  flex: 0.5;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):not(.closed):hover {
  background-color: #f8f9fb; /* $ui_f */
}

.ReactTable.-highlight .rt-tbody .rt-tr.closed {
  background-color: #d6d8db;
}

.ReactTable.-highlight .rt-tbody .rt-tr.closed:hover {
  background-color: #d6d8db;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
}
.ReactTable .-pagination {
  box-shadow: none;
  border-top-width: 1px;
}
