.add-client-form {
  .ant-row {
    display: block;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .cancel-button {
    margin-right: 20px;
    margin-left: 15px;
    font-size: 14px;
  }
}
