.ant-avatar {
  font-size: 18px;
}

.ant-avatar-lg {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

@avatar-bg: @gray-5;
