.participant-popover {
  .popover-button {
    font-size: 16px;
    font-weight: normal;
    padding-top: 0;

    .icon {
      vertical-align: middle;
      margin-right: 3px;
    }
  }
}

.participant-popover-content {
  max-width: 450px;

  .content-block {
    margin-bottom: 1em;
    p {
      margin-bottom: 0;
    }

    .content-header {
      font-size: 16px;
      font-weight: 600;
    }

    .content-description {
      font-size: 14px;
      color: #555E6D;
    }
  }
}
