.pinned-column {
  max-width: 27px;
  max-height: 32px;

  .pinned-icon {
    position: relative;
    top: 2px;
    color: #D67515;
    transform: rotate(-45deg);
    z-index: 1000
  }
}
