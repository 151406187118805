@import 'design-system/index';

.heading {
  margin-bottom: $halfSpacing;
}

.time {
  display: flex;
  color: $black;
  &:last-of-type {
    font-weight: $fontWeight_bold;
  }
}

.time__label {
  flex: 0 0 70px;
  margin-right: $halfSpacing;
  text-align: right;
}

.time__value {
  flex: 1 1 auto;
}
