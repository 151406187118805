.board-filter-form {
  margin-bottom: -20px;
}

@media screen and (min-width: 768px) {
  .board-filter-form {
    .select-form {
      width: 165px;
    }

    .input-form {
      width: 220px;
      .date-input {
        width: 220px;
      }
    }
  }
}
