.signer-detail-form {
  .signer-detail-header {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 400;
  }

  .work-phone-collapse {
    border: 0;
    padding-top: 7px;
  }

  .work-phone-information-panel {
    margin-bottom: 7px;
    color: #8792A1;
    border: 0;
    background-color: #FAFBFE;
  }

  .signer-email-address {
    padding-top: 10px ;
  }

  .add-participants-header {
    margin-bottom: 0;
    margin-left: 4px !important;
    h3 {
      font-size: 14px;
      font-weight: 700;
    }

    .popover-button {
      font-size: 13px;
      color: #555E6D;
      margin-bottom: 0;
      padding-top: 9px;
      margin-left: -3px;
    }
    input {
      opacity: 0;
    }
    input:focus {
      opacity: 1;
    }
  }

  .send-updates-to-signer-explanation {
    font-size: 12px;
    font-weight: bold;
    color: #555E6D;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #8792A1;
    padding-left: 20px;

    .ant-collapse-arrow {
      left: 5px;
    }
  }

  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-content.ant-collapse-content-active {
    border: 0;
    background-color: #FAFBFE;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .phone-input {
    width: 100%;
  }

  .input-mask {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #8792a1;
    border-radius: 2px;
    transition: all 0.3s;
  }
}
