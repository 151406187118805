.location-and-time-form {
  .appointment-date {
    height: 32px;
  }

  #appointmentDate {
    margin-bottom: 0px;
  }

  .property-address-panel {
    margin-bottom: 7px;
    color: #8792A1;
    border: 0px !important;
    background-color: #FAFBFE;
  }

  .property-address-info {
      color: #777777;
      font-size: 12px;
      font-style: italic;
      margin-bottom: 5px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .location-and-time-header {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #8792A1;
    padding-left: 20px;

    .ant-collapse-arrow {
      left: 5px;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-content.ant-collapse-content-active {
    border: 0px;
    background-color: #FAFBFE;
  }

  .property-address-collapse {
    border: 0px;
  }
}
