$fontSize_s: 0.857rem;
$fontSize_m: 1rem;
$fontSize_l: 1.142rem;
$fontSize_xl: 1.428rem;
$fontSize_xxl: 2rem;

$fontWeight_light: 400;
$fontWeight_medium: 500;
$fontWeight_bold: 600;

$lineHeight: 1.7em;
$lineHeight_small: 1.2em;
